import type { IRetrieveOptions, ItemModel, RecordSourceOptions, RecordSourceFieldType } from 'o365-dataobject';
import type { INodeDataLevelConfiguration, NodeDataStructureOptions } from './DataObject.NodeData.ts';

import { DataObject } from 'o365-dataobject';
import { $t } from 'o365-utils';

import { NodeItem } from './DataObject.NodeItem.ts';

export class GroupByLevelConfiguration<T extends ItemModel = ItemModel> implements INodeDataLevelConfiguration<T> {
    private _dataObject: DataObject<T>;
    private _field?: string & keyof T;
    private _fields?: (string & keyof T)[];
    private _aggregates?: NodeDataGroupByConfigurationOptions<T>['aggregates']
    private _key: string;
    private _disabled = false;
    private _pathField?: string;
    private _pathIdReplaceFn?: (pPathId: string | number, pGetDetails: (() => Promise<NodeItem<T>[]>)) => Promise<string | object>;
    private _pathMode = false;

    level = 0;
    getConfigurationForLevel: (pLevel: number) => INodeDataLevelConfiguration<T> | undefined;

    get field() {
        return this._field;
    }
    get fields() {
        return this._fields;
    }
    get ui() {
        return {
            title: this.isMultiField
                ? this._fields!.map(field => this._dataObject.fields[field]?.caption ?? field).join(' / ')
                : this._dataObject.fields[this.field!]?.caption ?? this._field!,
            type: $t('Group By')
        };
    }
    get key() {
        return this._key;
    }
    get isMultiField() {
        return this._fields != null;
    }

    get disabled() { return this._disabled; }
    set disabled(pValue) { this._disabled = pValue; }

    get pathMode() { return this._pathMode; }
    set pathMode(pValue) { this._pathMode = pValue; }

    get pathField() { return this._pathField; }

    isProperty = false;
    get propertyViewName() {
        return this._dataObject.propertiesData.viewName;
    }
    get propertyBinding() {
        const idField =  this._dataObject.propertiesData.itemIdField;
        const propertyIdFio =  this._dataObject.propertiesData.propertyIdField;
        return `T2.${propertyIdFio} = T1.${idField}`;
    }

    readonly type = 'groupBy'

    constructor(pDataObject: DataObject<T>, pOptions: NodeDataGroupByConfigurationOptions<T>, pGetConfigurationForLevel: (pLevel: number) => INodeDataLevelConfiguration<T> | undefined) {
        this._dataObject = pDataObject;
        this._field = pOptions.fieldName;
        this._fields = pOptions.fields;
        this._aggregates = pOptions.aggregates;
        this.getConfigurationForLevel = pGetConfigurationForLevel;
        this._key = window.crypto.randomUUID();
        this._pathField = pOptions.pathField;
        this._pathIdReplaceFn = pOptions.pathIdReplace;
        this._pathMode = pOptions.pathMode ?? false;

        if (this._field?.startsWith('Property.')) {
            this.isProperty = true;
        }
    }
2
    updateAggregates(pAggregates: NodeDataGroupByConfigurationOptions<T>['aggregates']) {
        this._aggregates = pAggregates;
    }

    /** Used in full structure mode */
    getNodes(pData: T[], pOptions: NodeDataStructureOptions) {
        const isLastConfiguration = this._dataObject.nodeData.configurations.length - 1 === this.level;
        const fields: (keyof T & string)[] = [];
        for (let i = 0; i <= this.level; i++) {
            const config = this.getConfigurationForLevel(i);
            if (config instanceof GroupByLevelConfiguration) {
                if (config.isMultiField) {
                    config.fields!.forEach(field => fields.push(field));
                } else {
                    fields.push(config.field!);
                }
            }
        }
        let result: [NodeItem<T>, T[]][] = [];
        let resultRoot: NodeItem<T>[] = [];
        const groups = new Map<string, T[]>();
        const summaries = new Map<string, T>();

        const linkedPaths = new PathNode<string>('root');

        const assignPath = (pScope: Map<string, PathNode<string>>, pValue: string, pSummary: T) => {
            if (!pScope.has(pValue)) { pScope.set(pValue, new PathNode(pValue)); summariesByPath.set(pValue, pSummary) }
            return pScope.get(pValue)!.children;
        }

        const nodesByPathMap = new Map<string, [NodeItem<T>, number]>();
        const summariesByPath = new Map<string, T>();
        const assignNodeToMap = (pItem: T, pNode: NodeItem<T>, pIndex: number) => {
            const pathString = (pItem[this._pathField!] as string) ?? '';
            const path = pathString.split('/').slice(1, -1);
            const pathId = path.at(-1)!;
            nodesByPathMap.set(pathId, [pNode, pIndex]);
        }

        pData.forEach(item => {
            const key = JSON.stringify(this._getKey(item, fields));
            if (!groups.has(key)) { groups.set(key, []); }
            groups.get(key)!.push(item);
            summaries.set(key, item);
            if (this.pathMode && this._pathField) {
                const pathString = (item[this._pathField!] as string) ?? '';
                const path = pathString.split('/').slice(1, -1);
                let activeScope = linkedPaths.children;
                path.forEach((id) => {
                    activeScope = assignPath(activeScope, id, item);
                });
            }
        });

        Array.from(groups.entries()).forEach((entry) => {
            const [key, data] = entry;
            const summary = summaries.get(key)!;
            const item: T = {} as T;
            const aggregates = this._aggregateData(data);
            const passthroughKeys = new Set<string>();
            if (aggregates) {
                Object.entries(aggregates).forEach(([key, value]) => {
                    item[key as keyof T] = value;
                    passthroughKeys.add(key);
                });
            }
            fields.forEach(field => {
                item[field] = summary[field];
                passthroughKeys.add(field);
            });
            const node = new NodeItem({
                key: this._getKey(item, fields),
                summaryItem: item,
                displayField: this._field,
                getSummaryValues: () => Promise.resolve(item),
                getNodeData: () => this._dataObject.nodeData,
                getConfiguration: () => this,
                passthroughKeys: Array.from(passthroughKeys)
            });
            node.updateItemPassthrough();
            if (pOptions.expandedKeys && pOptions.expandedKeys[node.key]) {
                node.expanded = true;
            } else if (pOptions.autoExpandOnFilter) {
                node.expanded = true;
            }
            if (this.pathMode && this._pathField) {
                assignNodeToMap(summary, node, result.length);
            }
            result.push([node, data])
            if (pOptions.startingLevel) {
                node.level = pOptions.startingLevel
            }
            resultRoot.push(node);
        });
        if (isLastConfiguration) {
            result.forEach(entry => {
                const [node, data] = entry;
                data.forEach(item => {
                    const detailNode = new NodeItem<T>({
                        key: [...node.keyArray, item.PrimKey],
                        fetchKey: item.PrimKey,
                        getNodeData: () => this._dataObject.nodeData,
                        getConfiguration: () => this,
                        refreshItem: (primKey: any) => this._dataObject.recordSource.refreshRowByPrimKey(primKey, {
                            appendRowCount: false,
                            returnExisting: true
                        }),
                        passthroughKeys: this._dataObject.fields.fields.map(x => x.name)
                    });
                    detailNode.updateItemPassthrough();
                    detailNode.level = node.level + 1;
                    node.details.push(detailNode);
                    detailNode.getParent = () => node;
                });
            });
        }

        if (this._pathField && this._pathMode) {
            const newTree: NodeItem<T>[] = []
            let activeKey: string[] = [];
            let activeNode: NodeItem<T> | null = null;
            let activeScope = newTree;
            const recursiveNodeReplace = (pNode: PathNode<string>, pLevel = 0) => {
                const subNodes = pNode.childrenArray; // Subnodesy of the current PathNode (aka directory)
                const scopeCopy = activeScope; // Reference cop of the current active directory
                const activeNodeCopy = activeNode; // Reference copy of the current parent node
                const activeKeyCopy = [...activeKey]; // Copy of the current active key
                subNodes.forEach(entry => {
                    const key = entry.value!;
                    if (key === '') {
                        if (nodesByPathMap.has(key)) {
                            const [node, index] = nodesByPathMap.get(key)!;
                            activeScope.push(...node.details.map((detail) => {
                                detail.level = pLevel;
                                return detail;
                            }));
                            nodesByPathMap.delete(key);
                        }
                        return;
                    }
                    activeKey.push(key);
                    const pathItem = new NodeItem({
                        key: [...activeKey],
                        getSummaryValues: async (pDetails) => {
                            if (this._pathItemReplaceFn) {
                                const item = await this._pathItemReplaceFn(key, pDetails);
                                return {
                                    ...summariesByPath.get(key),
                                    o365_Path_ID: key,
                                    o365_Path_Display: key,
                                    ...item,
                                }
                            }
                            let display = key;
                            if (this._pathIdReplaceFn) {
                                const getDetails = () => Promise.all(pDetails.map(detail => detail.loadAll()));
                                const result = await this._pathIdReplaceFn(key, getDetails);
                                if (typeof result == 'object') {

                                } else {
                                    display = result;
                                }
                            };
                            return {
                                o365_Path_ID: key,
                                o365_Path_Display: display,
                                ...summariesByPath.get(key)
                            } as any;
                        },
                        displayField: 'o365_Path_Display',
                        getNodeData: () => this._dataObject.nodeData,
                        getConfiguration: () => this,
                        passthroughKeys: ['o365_Path_ID', 'o365_Path_Display', ...this._dataObject.fields.fields.map(x => x.name)]
                    });
                    if (pOptions.expandedKeys && pOptions.expandedKeys[pathItem.key]) {
                        pathItem.expanded = true;
                    } else if (pOptions.autoExpandOnFilter) {
                        pathItem.expanded = true;
                    }
                    pathItem.level = pLevel;
                    pathItem.updateItemPassthrough();
                    activeScope.push(pathItem);
                    if (entry.childrenArray.length > 0) {
                        activeScope = pathItem.details;;
                        activeNode = pathItem;
                        recursiveNodeReplace(entry, pLevel + 1);
                    }
                    if (nodesByPathMap.has(key)) {
                        const [node, index] = nodesByPathMap.get(key)!;
                        const parent = pathItem;
                        pathItem.details.push(...node.details.map((detail) => {
                            detail.getParent = () => parent;
                            detail.level = pLevel + 1;
                            return detail;
                        }));
                        result[index][0] = parent;
                        nodesByPathMap.delete(key);
                    } else {
                        // pathItem.expanded = true;
                    }
                    activeKey = [...activeKeyCopy];
                    activeScope = scopeCopy;
                    activeNode = activeNodeCopy;
                });
            }
            recursiveNodeReplace(linkedPaths, pOptions.startingLevel ?? this.level);
            resultRoot = newTree;
        }

        return {
            root: resultRoot,
            boundry: result,
            depth: pOptions.startingLevel
        };
    }


    /** Server side (SQL group by) */
    async getStructure(pOptions: NodeDataStructureOptions, pNode?: NodeItem<T>) {
        const isLastConfiguration = this._dataObject.nodeData.configurations.length - 1 === this.level || this._dataObject.nodeData.configurations[this.level + 1]?.disabled;
        const options: RecordSourceOptions & Partial<IRetrieveOptions> = this._dataObject.recordSource.getOptions();
        this._dataObject.recordSource.updatePreviousWhereClause();
        options.skipClientSideHandlerCheck = true;
        options.maxRecords = -1;
        options.skip = 0;
        options.loadRecents = undefined;
        options.fields = [];

        const parentLevel = pNode?.level ?? -1;
        const parentFilterString = pNode?.getFilterString ? pNode.getFilterString() : undefined;
        if (parentFilterString) {
            if (options.whereClause) {
                options.whereClause = `${options.whereClause} AND ${parentFilterString}`;
            } else {
                options.whereClause = parentFilterString;
            }
        }

        const keyFields: string[] = [];
        for (let i = 0; i <= this.level; i++) {
            const config = this.getConfigurationForLevel(i);
            if (config instanceof GroupByLevelConfiguration) {
                if (this.isMultiField) {
                    this.fields!.forEach(field => {
                        keyFields.push(field);
                    });
                } else {
                    keyFields.push(config.field!);
                }
            }
        }
        options.fields = [
            { name: 'PrimKey', alias: '_count', aggregate: 'COUNT' }
        ];

        this._dataObject.useGroupedRequests = false;
        if (this.isProperty) {
            options.viewName = this._dataObject.nodeData.withPropertiesView;
            if (this._dataObject.nodeData.withPropertiesDefinitionProc) {
                options.definitionProc = this._dataObject.nodeData.withPropertiesDefinitionProc;
            }
            options.fields.push({ name: 'PropertyName', groupByOrder: 1 });
            options.fields.push({ name: 'PropertyValue', groupByOrder: 2 });
            const clause = `ISNULL([PropertyName], '') = '' OR [PropertyName] = '${this._field!.split('.')[1]}'`
            if (options.whereClause) {
                options.whereClause += ` AND (${clause})`;
            } else {
                options.whereClause = clause;
            }
        } else {
            keyFields.forEach((field, index) => {
                options.fields!.push({ name: field, groupByOrder: index + 1 });
            });
        }

        if (this._aggregates) {
            this._aggregates.forEach((field) => {
                const index = options.fields!.findIndex(x => x.name === field.name);
                if (index === -1) {
                    options.fields!.push(field);
                } else {
                    options.fields![index].groupByAggregate = field.aggregate;
                }
            });
        }

        const sortFields: (typeof options['fields']) = [];
        this._dataObject.recordSource.appendSortByFields(sortFields);
        sortFields.forEach(field => {
            const index = options.fields!.findIndex(x => x.name === field.name);
            if (index === -1) {
                options.fields!.push({
                    name: field.name,
                    alias: `_sort_${field.sortOrder}`,
                    aggregate: 'MIN',
                    sortOrder: field.sortOrder,
                    sortDirection: field.sortDirection
                });
            } else {
                options.fields![index].sortOrder = field.sortOrder;
                options.fields![index].sortDirection = field.sortDirection;
            }
        });

        const data = (await this._dataObject.dataHandler.retrieve(options)) as (T & { _count: number })[];
        const passthroughKeys = options.fields!.map(field => field.name);

        const result: NodeItem<T>[] = [];
        const displayField = this.isProperty ? 'PropertyValue' : this._field;
        data.forEach(item => {
            if (this.isProperty) {
                item[this._field] = item.PropertyValue
                if (pNode?.summaryItem) {
                    const originalItem = { ...item };
                    Object.assign(item, pNode.summaryItem);
                    Object.assign(item, originalItem);
                }
            }
            const node = new NodeItem({
                key: keyFields.map(field => item[field]),
                // getFilterString: () => keyFields.map(field => `ISNULL(${field}, '') = '${item[field] ?? ''}'`).join(' AND '),
                getFilterString: () => {
                    return this.getGroupFilterStringFromKeys(keyFields, item)
                },
                summaryItem: item,
                displayField: displayField,
                getNodeData: () => this._dataObject.nodeData,
                getConfiguration: () => this,
                passthroughKeys: passthroughKeys
            });
            node.level = parentLevel + 1;
            node.updateItemPassthrough();
            if (pOptions.expandedKeys && pOptions.expandedKeys[node.key]) {
                node.expanded = true;
            } else if (pOptions.autoExpandOnFilter && options.filterString) {
                node.expanded = true;
            }
            if (isLastConfiguration) {
                const placeholderNode = new NodeItem({
                    key: [...keyFields.map(field => item[field]), '<PLACEHOLDER>'],
                    getNodeData: () => this._dataObject.nodeData,
                    beforeLoad: (pNode) => this._replaceDataItemPlaceholder(pNode),
                    // getFilterString: () => keyFields.map(field => `iSNULL(${field},'') = '${item[field] ?? ''}'`).join(' AND '),
                    getFilterString: () => {
                        return this.getGroupFilterStringFromKeys(keyFields, item)
                    },
                    getConfiguration: () => this,
                    passthroughKeys: []
                });
                placeholderNode.getParent = () => node;
                placeholderNode.level = node.level + 1;
                node.details.push(placeholderNode);
            } else {
                const detailConfiguration = this._dataObject.nodeData.configurations[this.level + 1];
                if (detailConfiguration) {
                    const placeholderItem = detailConfiguration.getPlaceholder(node, pOptions);
                    placeholderItem.getParent = () => node;
                    node.details.push(placeholderItem);
                }
            }
            result.push(node);
        });
        return result;
    }

    getPlaceholder(pNode: NodeItem<T>, pOptions: NodeDataStructureOptions) {
        if (pNode.getFilterString == null) { throw new Error(`Can't load placeholder with no filter string`); }
        const placeholderNode: NodeItem<T> = new NodeItem<T>({
            key: [...pNode.keyArray, '<PLACEHOLDER>'],
            getNodeData: () => this._dataObject.nodeData,
            getFilterString: pNode.getFilterString,
            beforeLoad: (pNode) => this._replacePlaceholder(pNode, pOptions),
            getConfiguration: () => this,
            passthroughKeys: []
        });
        placeholderNode.level = pNode.level + 1;
        return placeholderNode;
    }

    private async _replacePlaceholder(pNode: NodeItem<T>, pOptions: NodeDataStructureOptions) {
        const parentNode = pNode.getParent();
        if (parentNode == null) { throw new Error(`Placeholder item does not have a parent`); }
        const nodes = await this.getStructure(pOptions, parentNode);
        parentNode.details.splice(0, parentNode.details.length);
        nodes.forEach(node => {
            node.getParent = () => parentNode;
            parentNode.details.push(node);
        });
        this._dataObject.nodeData.update();
    }

    private async _replaceDataItemPlaceholder(pNode: NodeItem<T>) {
        if (pNode.getFilterString == null) { throw new Error(`Can't load placeholder with no filter string`); }
        const parentNode = pNode.getParent();
        if (parentNode == null) { throw new Error('Placeholder item does not have a parent'); }
        parentNode.details.splice(0, parentNode.details.length);
        const filterString = pNode.getFilterString();
        const options = this._dataObject.recordSource.getOptions();
        options.fields = [{ name: 'PrimKey' }];
        options.skip = 0;
        options.maxRecords = -1;
        this._dataObject.recordSource.appendSortByFields(options.fields);
        if (options.whereClause) {
            options.whereClause = `(${options.whereClause}) AND ${filterString}`;
        } else {
            options.whereClause = filterString;
        }
        const data = await this._dataObject.recordSource.retrieve(options) as (T & { PrimKey: string })[];
        data.forEach((item) => {
            const detailNode = new NodeItem<T>({
                key: [...parentNode.keyArray, item.PrimKey],
                fetchKey: item.PrimKey,
                getNodeData: () => this._dataObject.nodeData,
                getConfiguration: () => this,
                refreshItem: (primKey: any) => this._dataObject.recordSource.refreshRowByPrimKey(primKey, {
                    appendRowCount: false,
                    returnExisting: true
                }),
                passthroughKeys: this._dataObject.fields.fields.map(x => x.name)
            });
            detailNode.updateItemPassthrough();
            detailNode.getParent = () => parentNode;
            detailNode.level = parentNode.level + 1;
            parentNode.details.push(detailNode);
        });
        this._dataObject.nodeData.update();
    }

    updatePathSetings(pOptions: {
        pathField?: string,
        pathFn?: (pId: string | number) => Promise<string>
    }) {
        this._pathField = pOptions.pathField;
        this._pathIdReplaceFn = pOptions.pathFn;
    }

    canCreateNodes() {
        return false;
    }

    updateNodeParent() {
        throw new TypeError(`Not Implemented`);
    }

    createNode(): NodeItem<T> {
        throw new TypeError(`Not Implemented`);
    }

    getRequiredFields() {
        const result: string[] = [];
        if (this.isMultiField) {
            this._fields!.forEach(field => result.push(field));
        } else {
            result.push(this.field!);
        }

        if (this._aggregates) {
            this._aggregates.forEach(aggregate => {
                result.push(aggregate.name);
            });
        }

        if (this._pathField && this._pathMode) {
            result.push(this._pathField);
        }

        return result;
    }

    getGroupFilterStringFromKeys(pFields: string[], pItem: any) {
        const propertyClauses: [string, any][] = [];
        const normalClauses: string[] = [];
        pFields.forEach(field => {
            if (field.startsWith('Property.')) {
                const propertyName = field.split('.')[1];
                const propertyValue = pItem[field];
                propertyClauses.push([propertyName, propertyValue]);
            } else {
                normalClauses.push(`iSNULL(${field},'') = '${pItem[field] ?? ''}'`);
            }
        });
        if (propertyClauses.length) {
            // const exists: string[] = [];
            // const notExists: string[] = [];

            // propertyClauses.forEach((p) => {
            //     if (p[1]) {
            //         exists.push(`([PropertyName] = '${p[0]}' AND ISNULL([Value],'') = '${p[1]}')`);
            //     } else {
            //         notExists.push(`([PropertyName] = '${p[0]})`);
            //     }
            // });
            // if (exists.length) {
            //     normalClauses.push(`exists_clause(${this.propertyViewName}, ${this.propertyBinding}, ${exists.join(' OR ')})`)
            // }
            // if (notExists.length) {
            //     normalClauses.push(`NOT exists_clause(${this.propertyViewName}, ${this.propertyBinding}, ${notExists.join(' OR ')})`)
            // }
            const clause = propertyClauses
                .map(p => {
                    if (p[1]) {
                        const clause = `T2.[PropertyName] = '${p[0]}' AND ISNULL(T2.[Value], '') = '${p[1]}'`;
                        return `exists_clause(${this.propertyViewName}, ${this.propertyBinding}, ${clause})`
                    } else {
                        const clause = `T2.[PropertyName] = '${p[0]}' AND ISNULL(T2.[Value], '') = ''`;
                        return `NOT exists_clause(${this.propertyViewName}, ${this.propertyBinding})`;
                        return `exists_clause(${this.propertyViewName}, ${this.propertyBinding}, ${clause})`;
                        return `(exists_clause(${this.propertyViewName}, ${this.propertyBinding}, ${clause}) OR NOT exists_clause(${this.propertyViewName}, ${this.propertyBinding}))`
                    }
                })
                // .map(p => `[PropertyName] = '${p[0]}' AND ISNULL([Value], '') = '${p[1] ?? ''}'`)
                // .map(c => { `exists_clause(${this.propertyViewName}, T2.Item_ID = T1.ID, ${c})` })
                .join(' AND ');
            normalClauses.push(clause);
        }

        return normalClauses.join(' AND ')
    }

    private _getKey<T2 extends object = object>(pItem: T2, pKeys: string[]) {
        return pKeys.map((key) => {
            const value = (pItem as any)[key]
            if (typeof value == 'string') {
                return value.toLocaleLowerCase().trim();
            } else {
                return value;
            }
        });
    }

    /**
     * Client side aggregation used when entire structure is being loaded
     */
    private _aggregateData(pData: T[]) {
        if (this._aggregates == null || this._aggregates.length == 0) { return null; }
        const aggregateFunctions = {
            COUNT: (data: T[], field: string) => data.reduce((count, item) => {
                if (item[field] != null) { count++; }
                return count;
            }, 0),
            SUM: (data: T[], field: string) => data.reduce((sum, item) => {
                if (item[field] != null) { sum += item[field]; }
                return sum;
            }, 0),
            AVG: (data: T[], field: string) => {
                const dataSum = data.reduce((sum, item) => {
                    if (item[field] != null) { sum += item[field]; }
                    return sum;
                }, 0);
                return dataSum / data.length;
            },
            MIN: (data: T[], field: string) => data.reduce((min, item) => {
                if (item[field] != null) {
                    if (min == null || item[field] < min) {
                        min = item[field];
                    }
                }
                return min;
            }, null),
            MAX: (data: T[], field: string) => data.reduce((max, item) => {
                if (item[field] != null) {
                    if (max == null || item[field] > max) {
                        max = item[field];
                    }
                }
                return max;
            }, null)
        };

        const result: Partial<T> = {};
        this._aggregates.forEach(aggregate => {
            result[aggregate.name as keyof T] = aggregateFunctions[aggregate.aggregate](pData, aggregate.name) as any;
        });
        return result;
    }
}

export type NodeDataGroupByConfigurationOptions<T extends ItemModel = ItemModel> = {
    type: 'groupBy',
    fieldName?: string;
    fields?: string[];
    aggregates?: NodeDataGroupByAggregate<T>[];
    pathField?: string;
    pathMode?: boolean;
    pathIdReplace?: (pPathId: string | number) => Promise<string>;
}

type NodeDataGroupByAggregate<T extends ItemModel = ItemModel> = {
    name: keyof T & string,
    aggregate: NonNullable<RecordSourceFieldType['aggregate']>
}

class PathNode<T> {
    children = new Map<T, PathNode<T>>();
    value?: T

    get childrenArray() { return Array.from(this.children.values()) }

    constructor(pValue: T) {
        this.value = pValue;
    }
}